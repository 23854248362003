<template>
    <AppLayout wrapperClass="home">
        <HeroBanner />
        <BestSellerProduct />
        <GridCategory />
        <Testimonial />
    </AppLayout>
</template>

<script>
import AppLayout from "@/esf_kerkrade_vitanatura/core/components/AppLayout.vue";
import GridCategory from "@/esf_kerkrade_vitanatura/core/components/GridCategory.vue";
import Testimonial from "@/esf_kerkrade_vitanatura/core/components/Testimonial.vue";
import HeroBanner from "@/esf_kerkrade_vitanatura/core/components/HeroBanner";
import BestSellerProduct from "@/esf_kerkrade_vitanatura/core/components/BestSellerProduct.vue";

export default {
    name: "Home",
    metaInfo: {
        title: 'Home',
        meta: [
            { name: 'description', content: 'Home Page | Vitanature' },
        ],
    },


    components: {
        AppLayout,
        HeroBanner,
        GridCategory,
        Testimonial,
        BestSellerProduct
    }
}
</script>

<style lang="scss">
$background-image: url('@/esf_kerkrade_vitanatura/assets/images/fresh-green-leaves-tree-branches-vibrant-forest-generated-by-artificial-intelligence.png');


.slick-next:before,
.slick-prev:before {
    color: #408309 !important;
}

.product_list_slider .slick-slider.slick-initialized,
.testimonial_slider .slick-slider.slick-initialized,
.gridCategory_slider .slick-slider.slick-initialized {
    margin-inline: 10%;
}

.SlickCarousel .slick-slider.slick-initialized {
    margin-inline: initial;
}

.mobile {
    .home {

        h2 {
            font-size: 25px;
            font-style: italic;
            font-weight: 700;
            line-height: 31.43px;
        }

        .testimonials {
            background-image: url('@/esf_kerkrade_vitanatura/assets/images/top-view-fresh-sage-plant.png');
            background-size: cover;
            background-repeat: no-repeat;
            padding-bottom: 40px;

            h2 {
                padding: 28px 0px 18px;
            }

        }

        .testimonials {
            padding-block: 21px 55px;
        }

        .unsereBestseller {
            padding-bottom: 20px;
        }

        ul.slick-dots {
            bottom: -35px;
        }

        .slick-dots li {
            border: 2px solid;
            border-radius: 50%;
        }

        li.slick-active {
            background: #408309 !important;
        }

        .slick-dots li button:before {
            color: #ffffff00 !important;
        }

        .product_list_slider,
        .testimonial_slider {
            display: block;
        }

        .gridCategory_slider {
            margin-top: 37px;
            margin-bottom: 50px;
        }

        .gridCategory,
        .product_list,
        .testimonial_list {
            display: none;
        }

        .heading {
            font-style: italic;
            font-weight: 700;
            font-size: 25px;
        }
    }

}

.desktop {
    .home {

        .testimonial_slider,
        .gridCategory_slider,
        .product_list_slider {
            display: none !important;
        }

        .gridCategory {
            display: flex;
            width: calc(100% - 20%);
            margin-inline: auto;
            gap: 2.5rem;
            padding-block: 50px;
            justify-content: center;
        }

        .testimonials {
            background-image: url('@/esf_kerkrade_vitanatura/assets/images/top-view-fresh-sage-plant.png');
            background-size: cover;
            background-repeat: no-repeat;
            padding-bottom: 40px;

            h2 {
                padding: 28px 0px 18px 0px;
            }

        }

        .testimonials>div {
            display: flex;
            justify-content: center;
            margin-inline: auto;
            gap: 1.75rem;
        }

        .product_list {
            display: flex;

            .product {
                width: 468px;
                height: 503px;
            }
        }

        .heading {
            font-style: italic;
            font-weight: 700;
            font-size: 50px;
        }

        .unsereBestseller {
            padding-bottom: 90px;
        }
    }
}
</style>