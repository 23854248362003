<template>
    <div class="unsereBestseller">
        <b-container>
            <div>
                <h2 class="heading text-center">UNSERE BESTSELLER</h2>
            </div>
            <SkeletonCard v-if="bestSellerProduct == null"/>
            <div class="product_list product-listing" v-if="bestSellerProduct != null">
                <ProductCard v-for="product in bestSellerProduct.slice(0, 3)" :key="product.id" 
                    :product="product" />
            </div>
            <div class="product_list_slider mb-20" v-if="bestSellerProduct != null">
                <VueSlickCarousel :dots="true" :arrows="true">
                    <ProductCard v-for="product in bestSellerProduct.slice(0, 3)" :key="product.id" :product="product" />
                </VueSlickCarousel>
            </div>
        </b-container>
    </div>
</template>
<script>
import { getBestSellers } from "@storefront/core/data-resolver/categories";
import { getProductBySku } from "@storefront/core/data-resolver/products";
import VueSlickCarousel from 'vue-slick-carousel';
import ProductCard from "@/esf_kerkrade_vitanatura/core/components/ProductCard.vue";
import config from "@config";
import { getCurrentLanguage } from "@storefront/core/i18n";
import SkeletonCard from "@/esf_kerkrade_vitanatura/core/components/SkeletonCard.vue"
export default {
    name: "BestSellerProduct",
    data() {
        return {
            bestSellerProduct: null
        }
    },
    async mounted() {
        await this.loadBestSellers();
    },
    methods: {
        async loadBestSellers() {
            const lang = getCurrentLanguage();
            const storelang = config.languages[lang];
            const root_category = storelang["root_category"];
            const data = await getBestSellers(root_category);
            const productDetails = data.tabs[0].items.map(item => ({
                sku: item.sku,
                imageUrl: item.image.url,
            }));
            await this.loadProductDetails(productDetails);
        },
        async loadProductDetails(productDetails) {
            const products = [];
            for (const detail of productDetails) {
                const { sku, imageUrl } = detail; // Destructure SKU and image URL
                const productData = await getProductBySku(sku);
                if (productData && productData.items && productData.items.length) {
                    const product = productData.items[0];
                    product.image.medium = imageUrl;
                    products.push(product);
                }
            }
            this.bestSellerProduct = products;
        }
    },
    components: {
        VueSlickCarousel,
        ProductCard,
        SkeletonCard
    }
}
</script>
<style lang="scss">
$background-image: url('@/esf_kerkrade_vitanatura/assets/images/fresh-green-leaves-tree-branches-vibrant-forest-generated-by-artificial-intelligence.png');


.unsereBestseller {
    display: flex;
    background: $background-image no-repeat center center;
    background-size: cover;
    flex-direction: column;

    h2 {
        padding: 40px
    }

    .product_list {
        display: flex;
        justify-content: center;
        gap: 2rem;
        flex-direction: row;
        margin-inline: auto;

        .product {
            .image {

                width: 180px !important;
                height: 272px !important;
                object-fit: cover !important;
                margin-inline: auto;
            }
        }
    }

    .product_list_slider {
        display: none;
    }
}

.product_list {
    display: none;
}
</style>